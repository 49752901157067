import request from '@/utils/request'

export function getCenterList(params) {
    return request({
        url: '/user/getUserProjectInfo',
        method: 'GET',
        params,
    })
}

export function getCenterProject(params) {
    return request({
        url: '/user/getMyProjectInfos',
        method: 'GET',
        params,
    })
}

export function getCenterTask(params) {
    return request({
        url: '/user/getMyProjectTasks',
        method: 'GET',
        params,
    })
}

export function getCenterMyProblem(params) {
    return request({
        url: '/user/getMyRaisedProblems',
        method: 'GET',
        params,
    })
}

export function getCenterOtherProblem(params) {
    return request({
        url: '/user/getMyAssignedProblems',
        method: 'GET',
        params,
    })
}

export function getMyApprove(params) {
    return request({
        url: '/user/getMyRaisedApprovals',
        method: 'GET',
        params,
    })
}

export function getOtherApprove(params) {
    return request({
        url: '/user/getMyAssignedApprovals',
        method: 'GET',
        params,
    })
}

export function getCenterAfterSale(params) {
    return request({
        url: '/user/getMyAfterSaleCares',
        method: 'GET',
        params,
    })
}

export function createApprove(data) {
    return request({
        url: '/projectTask/approve',
        method: 'POST',
        data,
    })
}








